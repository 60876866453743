import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import ExternalLinks from "./ExternalLinks";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const spotlightProjects = {
      "DigiDoc": {
        title: "DigiDocs",
        desc:
          "A mobile receipt tracker that uses a custom OCR algorithm to retrieve items bought and store details from Australian store receipts",
        techStack: "Android, Java, Firebase, Firestore",
        link: "https://github.com/Ssak4395/digidocs",
        image: "/assets/Woolworths.png"
      },
      LOnlyGames: {
        title: "Social Gaming",
        desc:
          "A web-application aimed at connecting lonely gamers with similar skill levels, we track skills in the most popular games (FPS, RPG, Strategy)",
        techStack: "React Spring Boot MySQL",
        link: "https://github.com/Ssak4395/Social_Gaming",
        image: "/assets/gaming.png"
      },
      "Health-Block": {
        title: "Health-Block",
        desc:
          "A Proof of Concept Health-Care Platform that is deployed on the Optimism Layer 2 network",
        techStack: "Node JS React Solidity Optimism Layer 2 Ethereum",
        link: "https://github.com/Ssak4395/health_block",
        image: "/assets/hcare.png"
      },
      FoodFair: {
        title: "Foodfair",
        desc:
          "A mobile application aimed at encouraging users to share their un-used food items/left overs through a global sharing platform.",
        techStack: "Spring Boot, Firebase, Firestore",
        link: "https://github.com/Ssak4395/foodfair",
        image: "/assets/foodshare.jpg"
      }
    };
    const projects = {
      "TimeTasker": {
        desc:
          "A web application aimed at streamlining student collaboration and calendar management",
        techStack: "Python (Flask), Angular, Bootstrap, SQL",
        link: "https://github.com/Ssak4395/TimeTasker",
      },
      "Gourmet Burgers": {
        desc:
          "A restaurant web application developed using Python and the Flask Micro-Framework",
        techStack: "Javascript, Flask, React",
        link: "https://github.com/Ssak4395/Gourmet-Burgers",
      },
      "Recognize": {
        desc:
          "An android mobile application that uses Machine Vision to detect items in the users vicinity and read-back using Text To Speech",
        techStack: "Firebase, Google Cloud Vision, Java, Firestore",
        link:
          "https://github.com/Ssak4395/Recognize"
      },
      "Bank-Service": {
        desc:
          "A demonstration on the effects of distributed computing, this program also showcases the power and importance of Transactions in an Enterprise setting.",
        techStack: "C#, ASP.NET, HTML, Javascript",
        link: "https://github.com/Ssak4395/Bank-Service",
      },
      "GramScanner": {
        desc:
          "An application used to scan, stock items and persist them in a database by execueting a store procedure in a ASP.NET API. The application uses the front facing camera as a barcode and QR Code detector and then parses the web or a database to extract item infomation",
        techStack: "Java, Android Studio",
        link: "https://github.com/Ssak4395/GramScanner",
      },
      "T-Bot": {
        desc:
          "A discord bot written in Java leveraging the Discord API, this bot is used in a private server with a bunch of close friends :)",
        techStack: "Java",
        link: "https://github.com/Ssak4395/TBot",
      }
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ software-creations</span>
        </div>
        <Carousel>
          {Object.keys(spotlightProjects).map((key, i) => (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={spotlightProjects[key]["image"]}
                alt={key}
              />
              <div className="caption-bg">
                <Carousel.Caption>
                  <h3>{spotlightProjects[key]["title"]}</h3>
                  <p>
                    {spotlightProjects[key]["desc"]}
                    <p className="techStack">
                      {spotlightProjects[key]["techStack"]}
                    </p>
                  </p>
                  <ExternalLinks
                    githubLink={spotlightProjects[key]["link"]}
                    openLink={spotlightProjects[key]["open"]}
                  ></ExternalLinks>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <ExternalLinks
                      githubLink={projects[key]["link"]}
                      openLink={projects[key]["open"]}
                    ></ExternalLinks>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
