import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "Macquarie Capital (Macquarie Group)": {
      jobTitle: "Software Engineer @",
      duration: "JAN 2023 - PRESENT",
      desc: [
        "Contributed to algorithmic trading processes, wrote a VWAP (Volume Weighted Average Price) alert that would notify traders whenever a benchmark algorithm was behind user set parameters (slice pct, time taken)",
        "Refactored algorithmic reference data, to utilize SQL Bulk Insertion library, this led to a 300% increase in parsing a SQL Table filled with 7 million rows",
        "Created new web pages (Angular JS) for altering, auditing and viewing tables in a MySQL database, this feature involved being able to audit(view who made the changes and to see if business approval was requested)",
        "Refactored new and existing pages to include a bulk commit/amend/clone feature, this greatly increased the time for business analysts to upload data into our tables",
        "Refactored AlgoDataAPI, this API is a .NET Core 4 intermediary that is used to run AlgoAdministrator and other auxiliary services, my changes involved updating existing code to handle new tables, as well as exposing auditability to existing tables.",
        "Wrote mocks, unit tests testing various endpoints, simtests,and tested alert functionality in a simulated market environment."
      ]
    },
    "University Of Sydney": {
      jobTitle: "Honours Researcher @",
      duration: "FEB 2022 - NOV 2022",
      desc: [
        "Conducted research as apart of my Honours degree,under the guidance of Dr Sadiq Sani, I conducted research on health-care platforms in the blockchain/Ethereum.",
        "Learnt about new technologies such as Solidity/Blockchain. My research focused on demonstrating whether it was financially feasible to develop a large scale health-care platform on the blockchain",
        "Developed a proof of concept system called health-block, this application had a React + Nodejs + Solidity stack, where solidity was used to write smart contracts. In order to minimize transactional costs we deployed our smart contracts on Optimism Layer 2 chain."
      ]
    },
    "Macquarie Group": {
      jobTitle: "Software Engineering Intern @",
      duration: "JUN 2021 - DEC 2021",
      desc: [
        " Created a user change tracking feature for a server deployment web application called Google Cloud Portal, used company wide by other departments.",
        " Completed JIRA tickets that are aimed at feature development, bug fixes and quality of life improvements. Utilized the circuit breaker design pattern to ensure core APIs would fail gracefully ensuring other subsystems were not affected",
        "Transitioned the software stack from JavaScript to Typescript, to ensure better usability for clients and easier development for engineers",
        "Wrote documentation aimed at generalizing common tasks used by users of the platform. Also wrote system diagrams, sequence diagrams and class diagrams, aimed at modelling the different components and subsystems of Google Cloud Portal"
      ]
    },
    "Accenture": {
      jobTitle: "Software Engineering Intern @",
      duration: "JUN 2021 - DEC 2021",
      desc: [
        "Worked on an Automation platform called MyWizard, developed and refactored APIs in Spring Boot, one of these APIs were responsible for status tracking.",
        "Participated in requirements engineering with senior developers and business analysts",
          "Assisted developers with unit and UAT testing, unit testing involved creating mocks of modules and ensuring relatively high coverage of test cases."
      ]
    },
    "NSW Health": {
      jobTitle: "Software Engineering Intern @",
      duration: "FEB 2021 - JUN 2021",
      desc: [
        "Developed a proximity tracking feature using Google Maps API, that notified users when they are in a area of high Covid-19 rates.",
        "Conducted requirements analysis with key stakeholders to gauge the minimum viable product and understand the core features needed in the platform",
        "Created UI mock-ups of a medicine matching application (Medmatch) used to match intensive care medicine with general care pharmaceuticals"
      ]
    },
    "Deloitte": {
      jobTitle: "Software Engineering Intern @",
      duration: "DEC 2020 - FEB 2021",
      desc: [
        "Coordinated with stakeholders, internal team members, to assist my team in launching a new Buy Now Pay Later service aimed at businesses.",
        "Formulated documentation regarding system design, API Schema and business rules.",
        "Created a PDF injection module, that is able to preload user details into a PDF, ensuring that loan officers can promptly send loan applications",
        "Conducted unit-testing, during each sprint cycle, leveraged tools such as Selenium and JUnit to assist in automated testing"
      ]
    },
    "Gram Engineering": {
      jobTitle: "Software Engineering @",
      duration: "Oct 2020 - DEC 2020",
      desc: [
        "Developed a Node.js smart home system through Facebook’s Messenger integrated with Bocco sen- sors and other smart devices (Nest camera, TPLink smart plugs) to derive conclusions about the current state of the home",
        "Identified continuous improvements in data quality, design reports and coding activities, presenting results and findings to internal business stakeholders.",
        "Relevant technologies/tools used: DialogFlow, Vision, AutoML, Messenger Bot API, MongoDB."
      ]
    },
    "Riddles Tech": {
      jobTitle: "Software Engineering @",
      duration: "Jan 2020 - Nov 2020",
      desc: [
        "Designed, developed and launched a mobile application which leveraged OCR to capture details from shopping reciepts, this info was then persisted locally and on the cloud through GCP.",
        "Performed requirements engineering, to ideate, document and conceptualize features, crucial to the stakeholder",
        "Assisted senior developers in documenting the product, ran workshops, that demoed the application to core stakeholders"
      ]
    }

  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
